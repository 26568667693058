// Global imports
import { Link, Tag, Utils } from '@ukhomeoffice/cop-react-components';
import config from 'react-global-configuration';
import { useTranslation } from 'react-i18next';
import { useNavigation } from 'react-navi';

// Styles
import './PhaseBanner.scss';

const DEFAULT_CLASS = 'govuk-phase-banner';
const PhaseBanner = () => {
  const { t } = useTranslation();
  const uiVersion = config.get('uiVersion');
  const uiEnvironment = (config.get('uiEnvironment') || '').toLowerCase();
  const feedbackUrl = config.get('feedbackUrl');
  const navigation = useNavigation();

  const classes = Utils.classBuilder(DEFAULT_CLASS, []);
  const inProd = uiEnvironment.indexOf('prod') === 0;

  window.onpopstate = () => {
    const pathName = window.location.pathname;
    const regex =
      /^\/tasks\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}\/[a-zA-Z0-9-_]+$/;
    if (
      (pathName.includes('/forms/') && !pathName.endsWith('/in-progress')) ||
      regex.test(pathName)
    ) {
      navigation.navigate('/');
    }
  };

  return (
    <div className={`${classes()} hods-width-container`} role="complementary">
      <p className={classes('content')}>
        <Tag className={classes('content__tag')}>{uiVersion}</Tag>
        {!inProd && <Tag className={classes('content__tag')}>{uiEnvironment}</Tag>}
        <span className={classes('text')}>
          {t('header.new-service-1')}&nbsp;
          <Link
            className="govuk-link"
            href={`${feedbackUrl}?id=take_survey&type_id=e0e2125b1bdd1110f8655946464bcbf7`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('header.new-service-2')}
          </Link>
          &nbsp;
          {t('header.new-service-3')}
        </span>
      </p>
    </div>
  );
};

export default PhaseBanner;
