import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/index.scss';
import './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { TeamContextProvider } from './utils/TeamContext';
import { CurrentGroupContextProvider } from './utils/CurrentGroupContext';
import { GroupsContextProvider } from './utils/GroupsContext';
import { LocationContextProvider } from './utils/LocationContext';
import { UserContextProvider } from './utils/UserContext';

ReactDOM.render(
  <React.StrictMode>
    <TeamContextProvider>
      <CurrentGroupContextProvider>
        <GroupsContextProvider>
          <UserContextProvider>
            <LocationContextProvider>
              <App />
            </LocationContextProvider>
          </UserContextProvider>
        </GroupsContextProvider>
      </CurrentGroupContextProvider>
    </TeamContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
