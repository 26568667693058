import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const LocationContext = createContext({
  location: null,
  setLocation: null,
});

export const LocationContextProvider = ({ children }) => {
  const [location, setLocation] = useState(null);

  return (
    <LocationContext.Provider value={{ location, setLocation }}>
      {children}
    </LocationContext.Provider>
  );
};

LocationContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
