import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const TeamContext = createContext({
  team: null,
  teamGroup: null,
  setTeam: null,
  setTeamGroup: null,
});

export const TeamContextProvider = ({ children }) => {
  const [team, setTeam] = useState(null);
  const [teamGroup, setTeamGroup] = useState(null);

  return (
    <TeamContext.Provider value={{ team, setTeam, teamGroup, setTeamGroup }}>
      {children}
    </TeamContext.Provider>
  );
};

TeamContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
