import React, { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const AccessibilityStatement = () => {
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView();
  }, []);

  return (
    <div className="hods-width-container">
      <main className="govuk-main-wrapper">
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <h1 className="govuk-heading-l">
              Accessibility statement for Central Operations Platform
            </h1>
            <p className="govuk-body">
              This accessibility statement contains information about the{' '}
              <a href="https://www.cop.homeoffice.gov.uk" target="_blank" rel="noopener noreferrer">
                Central Operations Platform (COP) (opens in a new tab)
              </a>
              , a digital service run by Border Force.
            </p>
            <p className="govuk-body">
              We want as many people as possible to be able to use COP, regardless of access needs
              due to a disability or condition. For example, that means you should be able to:
            </p>
            <ul className="govuk-list govuk-list--bullet">
              <li>zoom in up to 300% without the text spilling off the screen</li>
              <li>navigate most of the website only using a keyboard</li>
              <li>always return to the homepage from every page</li>
              <li>read and navigate COP in a logical order</li>
            </ul>
            <p className="govuk-body">
              We have also made the website text as simple as possible to understand.
            </p>
            <p>
              <a href="https://mcmw.abilitynet.org.uk/" target="_blank" rel="noopener noreferrer">
                AbilityNet (opens in new tab)
              </a>{' '}
              has advice on making your device easier to use if you have a disability.
            </p>
            <h2 className="govuk-heading-m">How accessible COP is</h2>
            <p className="govuk-body">
              We know some parts of this website are not fully accessible. You can see a full list
              of any issues we currently know about in the non-accessible content section of this
              statement.
            </p>
            <p className="govuk-body">
              Some people may find parts of this service difficult to use. We have split up the
              reasons why COP may be difficult to use by the service areas in the ‘non-compliance
              with accessibility regulations’ section.
            </p>
            <h2 className="govuk-heading-m">Feedback and contact information</h2>
            <p className="govuk-body">
              If you find any problems not listed on this page or think we are not meeting
              accessibility requirements:
            </p>
            <ul className="govuk-list govuk-list--bullet">
              <li>
                use the chat service on{' '}
                <a
                  href="https://lssiprod.service-now.com/ess?id=index"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ITNow
                </a>
              </li>
              <li>
                <a
                  href="https://lssiprod.service-now.com/ess?id=index"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ‘Report an issue’ on ITNow
                </a>
              </li>
              <li>call the service desk on 0300 073 4444</li>
              <li>
                email
                <a
                  href="mailto:Kerry.Benfield@homeoffice.gov.uk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Kerry.Benfield@homeoffice.gov.uk
                </a>
              </li>
            </ul>
            <h2 className="govuk-heading-m">Enforcement procedure</h2>
            <p className="govuk-body">
              The Equality and Human Rights Commission (EHRC) is responsible for enforcing the
              Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility
              Regulations 2018 (the ‘accessibility regulations’).
            </p>
            <p className="govuk-body">
              If you are not happy with how we respond to your complaint,{' '}
              <a
                href="https://www.equalityadvisoryservice.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                contact the Equality Advisory and Support Service (EASS)
              </a>
              .
            </p>
            <p className="govuk-body">
              If you are in Northern Ireland and are not happy with how we respond to your
              complaint, contact the{' '}
              <a href="https://www.equalityni.org/home" target="_blank" rel="noopener noreferrer">
                Equality Commission for Northern Ireland (opens in new tab)
              </a>{' '}
              who are responsible for enforcing the Public Sector Bodies (Websites and Mobile
              Applications) (No. 2) Accessibility Regulations 2018 (the ‘accessibility regulations’)
              in Northern Ireland.
            </p>
            <h2 className="govuk-heading-m">Technical information about COP’s accessibility</h2>
            <p className="govuk-body">
              The Home Office is committed to making its website accessible, in accordance with the
              Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility
              Regulations 2018.
            </p>
            <h3 className="govuk-heading-s">Compliance status</h3>
            <p className="govuk-body">
              This website is not compliant with the{' '}
              <a href="https://www.w3.org/TR/WCAG22/" target="_blank" rel="noopener noreferrer">
                Web Content Accessibility Guidelines (WCAG) version 2.2 AA standard (opens in new
                tab)
              </a>
              .
            </p>
            <h2 className="govuk-heading-m">Non-accessible content</h2>
            <p className="govuk-body">
              The content listed below is non-accessible for the following reasons.
            </p>
            <h3 className="govuk-heading-m">Non-compliance with the accessibility regulations</h3>
            <h4 className="govuk-heading-s">Across all of COP</h4>
            <p className="govuk-body">
              If you are using a mobile device, you may need to scroll left to right to see all the
              information on some of COP pages. Also, longer text may spill in drop down lists. This
              fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG21/#reflow"
                target="_blank"
                rel="noopener noreferrer"
              >
                1.4.10 Reflow
              </a>
              .
            </p>
            <p className="govuk-body">
              The skip to content does not highlight the body of text. This means assistive
              technologies might find it difficult to inform you if you cannot read the page. This
              fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#bypass-blocks"
                target="_blank"
                rel="noopener noreferrer"
              >
                2.4.1 Bypass Blocks
              </a>
              . Frames must have <code>title</code> attribute for screen readers.
            </p>
            <p className="govuk-body">
              The single sign-on (SSO) page <code>lang</code> attribute is required for screen
              reader to set the language. This fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#language-of-page"
                target="_blank"
                rel="noopener noreferrer"
              >
                3.1.1 Language of Page
              </a>
              .
            </p>
            <h4 className="govuk-heading-s">The homepage</h4>
            <p className="govuk-body">
              The homepage contains elements other than list items within a set of list elements so
              screen readers cannot inform the listener they are listening to items within the list.
              This fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#info-and-relationships"
                target="_blank"
                rel="noopener noreferrer"
              >
                1.3.1 Info and Relationships
              </a>
              .
            </p>
            <h4 className="govuk-heading-s">Forms</h4>
            <h5 className="govuk-heading-s">
              &apos;Make your mandatory declarations v2&apos; and &apos;Luton and Stansted pilot:
              Add border event&apos;
            </h5>
            <p className="govuk-body">
              The JAWS screen reader breaks up sentences when reading them. You may have to use the
              arrow keys to hear the rest of the sentence. This fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#info-and-relationships"
                target="_blank"
                rel="noopener noreferrer"
              >
                1.3.1 Info and Relationships
              </a>
              .
            </p>
            <p className="govuk-body">
              ‘Check your answers’ pages have description lists with incorrect child elements. This
              fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#info-and-relationships"
                target="_blank"
                rel="noopener noreferrer"
              >
                1.3.1 Info and Relationships
              </a>
              .
            </p>
            <p className="govuk-body">
              The ordered list element on task list pages contains incorrect child elements. This
              fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#info-and-relationships"
                target="_blank"
                rel="noopener noreferrer"
              >
                1.3.1 Info and Relationships
              </a>
              .
            </p>
            <p className="govuk-body">
              Links disappear when CSS is disabled. If you customise the styling of the page because
              of a visual impairment, you may not be able to view links on the page. This fails WCAG
              2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#info-and-relationships"
                target="_blank"
                rel="noopener noreferrer"
              >
                1.3.1 Info and Relationships
              </a>
              .
            </p>
            <p className="govuk-body">
              The question for &apos;What does the [jobholder name] need to change?&apos; is not
              read out when tabbing. You may need to use the arrow keys to navigate the page. This
              fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#meaningful-sequence"
                target="_blank"
                rel="noopener noreferrer"
              >
                1.3.2 Meaningful Sequence
              </a>
              .
            </p>
            <p className="govuk-body">
              Radio buttons are missing when linearising some pages. Assistive technologies might
              not be able to determine the reading order of the page. This fails WCAG 2.2 success
              criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#meaningful-sequence"
                target="_blank"
                rel="noopener noreferrer"
              >
                1.3.2 Meaningful Sequence
              </a>
              .
            </p>
            <p className="govuk-body">
              Some text appears off the screen which requires you to scroll horizontally to view all
              the information. This fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#reflow"
                target="_blank"
                rel="noopener noreferrer"
              >
                1.4.10 Reflow
              </a>
              .
            </p>
            <p className="govuk-body">
              When starting a new page in ‘Make your mandatory declarations v2’, you sometimes
              unexpectedly end up at the bottom of the page and need to scroll back to the top. This
              fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#focus-order"
                target="_blank"
                rel="noopener noreferrer"
              >
                2.4.3 Focus Order
              </a>
              .
            </p>
            <p className="govuk-body">
              When selecting email addresses to send an email to, there is no indication that your
              mailbox will open. This fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#link-purpose-in-context"
                target="_blank"
                rel="noopener noreferrer"
              >
                2.4.4 Link Purpose (In Context)
              </a>
              .
            </p>
            <p className="gopvuk-body">
              When there is one question on a page, the label is not linked to the input. The JAWS
              screen reader does not read out the text so you may need to navigate using the arrow
              keys. This fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#headings-and-labels"
                target="_blank"
                rel="noopener noreferrer"
              >
                2.4.6 Headings and Labels
              </a>
              .
            </p>
            <p className="govuk-body">
              Heading level 2 is incorrect. You may not be able to understand the hierarchy of
              headings and screen readers may not convey the look of the page correctly. This fails
              WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#headings-and-labels"
                target="_blank"
                rel="noopener noreferrer"
              >
                2.4.6 Headings and Labels
              </a>
              .
            </p>
            <p className="govuk-body">
              Assistive technologies will not be able to differentiate between elements as they have
              the same IDs. This fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#parsing"
                target="_blank"
                rel="noopener noreferrer"
              >
                4.1.1 Parsing
              </a>
              .
            </p>
            <p className="govuk-body">
              Some <code>paragraph</code> elements have attributes that are not allowed. Assistive
              technologies might not be able to determine the content correctly. This fails WCAG 2.2
              success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#parsing"
                target="_blank"
                rel="noopener noreferrer"
              >
                4.1.1 Parsing
              </a>
              .
            </p>
            <p className="govuk-body">
              Some form elements do not have labels. This could interfere with your assistive
              technologies, such as screen readers. This fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#name-role-value"
                target="_blank"
                rel="noopener noreferrer"
              >
                4.1.2 Name, Role, Value
              </a>
              .
            </p>
            <h4 className="govuk-heading-s">All other forms</h4>
            <p className="govuk-body">
              We autocomplete certain forms with information from your COP profile, not from the
              browser. This fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#identify-input-purpose"
                target="_blank"
                rel="noopener noreferrer"
              >
                1.3.5 Identity Input Purpose
              </a>
              .
            </p>
            <p className="govuk-body">
              Adding additional instances of a response, such as a second passport number, may make
              the form tab to the top of the page. You may have to Shift + Tab to fill in the new
              fields if you use keyboard navigation. This fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#keyboard"
                target="_blank"
                rel="noopener noreferrer"
              >
                2.1.1 Keyboard
              </a>
              .
            </p>
            <p className="govuk-body">
              With drop down lists that contain the &apos;type to search&apos; function, users are
              unable to scroll through the drop-down list and then go back to the &apos;type to
              search&apos; function. This fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#keyboard"
                target="_blank"
                rel="noopener noreferrer"
              >
                2.1.1 Keyboard
              </a>
              .
            </p>
            <p className="govuk-body">
              The screen reader does not read out all the options for the &apos;port&apos; drop down
              list in the &apos;Luton and Stansted pilot: Add Border Event&apos; and
              &apos;Intelligence Referral&apos; forms as not all options are displayed. You will
              need to use the &apos;type to search&apos; function to find the correct port. This
              fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#no-keyboard-trap"
                target="_blank"
                rel="noopener noreferrer"
              >
                2.1.2 No Keyboard Trap
              </a>
              .
            </p>
            <p className="govuk-body">
              Help for ‘Events at the Border’ (EaB) and ‘Operational Activity Report’ have pages
              with tables and collapsible fields that are not read by the screen reader. You will
              not be able to access these if you use keyboard navigation only. This fails WCAG 2.2
              success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#no-keyboard-trap"
                target="_blank"
                rel="noopener noreferrer"
              >
                2.1.2 No Keyboard Trap
              </a>
              .
            </p>
            <p className="govuk-body">
              If you have the JAWS screen reader running in the background, the focus of the
              selected option can get stuck in the drop-down list, and you will not be able to get
              out of it using the keyboard. This fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#no-keyboard-trap"
                target="_blank"
                rel="noopener noreferrer"
              >
                2.1.2 No Keyboard Trap
              </a>
              .
            </p>
            <p className="govuk-body">
              The &apos;operational forms&apos; page reads the label of all the buttons as the same
              button as they are all called &apos;start&apos;. On the &apos;tasks assigned to
              you&apos; page all buttons are read as &apos;actions&apos; and on the &apos;tasks
              assigned to your team&apos; page all buttons are read as &apos;claim&apos;. If you are
              using the JAWS screen reader, you will be unable to tell which form you are starting,
              or which task you are actioning or claiming as they all have the same name. This fails
              WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#label-in-name"
                target="_blank"
                rel="noopener noreferrer"
              >
                2.5.3 Label in Name
              </a>
              .
            </p>
            <p className="govuk-body">
              Effective form labels are required to make forms accessible. This fails WCAG 2.2
              success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#labels-or-instructions"
                target="_blank"
                rel="noopener noreferrer"
              >
                3.3.2 Labels or Instructions
              </a>
              .
            </p>
            <p className="govuk-body">
              Assistive technologies will not be able to differentiate between elements as they have
              the same IDs. This fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#parsing"
                target="_blank"
                rel="noopener noreferrer"
              >
                4.1.1 Parsing
              </a>
              .
            </p>
            <p className="govuk-body">
              Status messages and validation errors can be read by screen readers; however, screen
              readers are unable to read questions within the form. This fails WCAG 2.2 success
              criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#status-messages"
                target="_blank"
                rel="noopener noreferrer"
              >
                4.1.3 Status Messages
              </a>
              .
            </p>
            <h4 className="govuk-heading-s">Reports</h4>
            <p className="govuk-body">
              The charts displayed in the reports section that display from Power BI do not provide
              effective contrast. This fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#contrast-minimum"
                target="_blank"
                rel="noopener noreferrer"
              >
                1.4.3 Contrast (Minimum)
              </a>
              .
            </p>
            <p className="govuk-body">
              The command brief report has case links inside which have missing link names. These
              may not be visible if you use a screen reader. This fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#link-purpose-in-context"
                target="_blank"
                rel="noopener noreferrer"
              >
                2.4.4 Link Purpose (In Context)
              </a>
              .
            </p>
            <p className="govuk-body">
              The scrollable region of the command brief report is not accessible by keyboard. This
              fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#focus-visible"
                target="_blank"
                rel="noopener noreferrer"
              >
                2.4.7 Focus Visible
              </a>
              .
            </p>
            <p className="govuk-body">
              Assistive technologies will not be able to differentiate between elements as they have
              the same IDs. This fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#parsing"
                target="_blank"
                rel="noopener noreferrer"
              >
                4.1.1 Parsing
              </a>
              .
            </p>
            <h4 className="govuk-heading-s">Cases</h4>
            <p className="govuk-body">
              The generate case PDF combo boxes do not have accessible names or descriptions. This
              means that assistive technologies may not be able to correctly inform you of the
              webpage, as it will not know what the combo boxes are. This fails WCAG 2.2 success
              criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#name-role-value"
                target="_blank"
                rel="noopener noreferrer"
              >
                4.1.2 Name, Role, Value
              </a>
              .
            </p>
            <p className="gopvuk-body">
              Case view accordions do not have valid aria-attributes. This fails WCAG 2.2 success
              criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#name-role-value"
                target="_blank"
                rel="noopener noreferrer"
              >
                4.1.2 Name, Role, Value
              </a>
              .
            </p>
            <p className="gopvuk-body">
              Using a JAWS screen reader informs users of the incorrect way to interact with the
              generate case PDF combo box. This fails WCAG success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#name-role-value"
                target="_blank"
                rel="noopener noreferrer"
              >
                4.1.2 Name, Role, Value
              </a>
              .
            </p>
            <h4 className="govuk-heading-s">Targets</h4>
            <p className="govuk-body">
              You cannot use targets on a mobile device. This means you will have to use a desktop
              or laptop to view targets.
            </p>
            <p className="govuk-body">
              The ‘RoRo Captured Targets’ screen has a horizontal scroll bar. This means you will
              have to horizontally scroll to see all the information on the page, which could be
              difficult if you have motor disabilities or impairments. This fails WCAG 2.2 success
              criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#reflow"
                target="_blank"
                rel="noopener noreferrer"
              >
                1.4.10 Reflow
              </a>
              .
            </p>
            <p className="govuk-body">
              If you use keyboard navigation, you must tab through all the targets to reach the
              ‘assign’ button. This fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#keyboard"
                target="_blank"
                rel="noopener noreferrer"
              >
                2.1.1 Keyboard
              </a>
              .
            </p>
            <p className="govuk-body">
              The ‘clear filter’ button is not reachable if you use keyboard navigation. This fails
              WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#keyboard"
                target="_blank"
                rel="noopener noreferrer"
              >
                2.1.1 Keyboard
              </a>
              .
            </p>
            <p className="govuk-body">
              The heading levels do not match the headings that are presented visually. This means
              assistive technologies will not be able to inform you of the correct layout of the
              page. This fails WCAG success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#headings-and-labels"
                target="_blank"
                rel="noopener noreferrer"
              >
                2.4.6 Headings and Labels
              </a>
              .
            </p>
            <p className="govuk-body">
              Drop down fields do not have accessible names. This means assistive technologies may
              find it difficult to read the page and inform you of how the page is laid out. This
              fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#name-role-value"
                target="_blank"
                rel="noopener noreferrer"
              >
                4.1.2 Name, Role, Value
              </a>
              .
            </p>
            <h4 className="govuk-heading-s">Your tasks and team tasks</h4>
            <p className="govuk-body">
              The &apos;due date&apos; section of the page is in a <code>complementary</code>{' '}
              landmark inside of the <code>main</code> landmark of the page. This is not accessible
              as a due date does not have meaning on its own without relating to a task. This fails
              WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#info-and-relationships"
                target="_blank"
                rel="noopener noreferrer"
              >
                1.3.1 Info and Relationships
              </a>
              .
            </p>
            <p className="govuk-body">
              The list element has direct children that are not allowed. This means that assistive
              technologies will not be able to read the webpage correctly. This fails WCAG 2.2
              success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#info-and-relationships"
                target="_blank"
                rel="noopener noreferrer"
              >
                1.3.1 Info and Relationships
              </a>
              .
            </p>
            <p className="govuk-body">
              You will not be able to see the page correctly when you change the screen orientation
              on a mobile device as the &apos;sort&apos; and &apos;search task&apos; components
              overlap. This fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#orientation"
                target="_blank"
                rel="noopener noreferrer"
              >
                1.3.4 Orientation
              </a>
              .
            </p>
            <p className="govuk-body">
              In team tasks, the email address of the assignee is obstructed by the ‘claim’ button.
              This means you may find it difficult to see or click on the ‘claim’ button if you have
              a visual or motor impairment. This fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#resize-text"
                target="_blank"
                rel="noopener noreferrer"
              >
                1.4.4 Resize Text
              </a>
              .
            </p>
            <p className="govuk-body">
              The search bar begins searching when you start typing. This will change the page
              automatically before you click the &apos;search&apos; button. This fails WCAG 2.2
              success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#on-input"
                target="_blank"
                rel="noopener noreferrer"
              >
                3.2.2 On Input
              </a>
              .
            </p>
            <p className="govuk-body">
              The <code>aria-describedby</code> attribute does not point to an element on the same
              page. This fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#parsing"
                target="_blank"
                rel="noopener noreferrer"
              >
                4.1.1 Parsing
              </a>
              .
            </p>
            <p className="govuk-body">
              All &apos;action task&apos; buttons have the same ID attributes. You may not be able
              to differentiate between tasks you want to action. This fails WCAG 2.2 success
              criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#parsing"
                target="_blank"
                rel="noopener noreferrer"
              >
                4.1.1 Parsing
              </a>
              .
            </p>
            <h4 className="govuk-heading-s">Help and about pages</h4>
            <p className="govuk-body">
              There is no transcript or audio description provided for ‘Make your mandatory
              declarations v2’ and COP reports in the help pages. This fails WCAG 2.2 success
              criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#audio-only-and-video-only-prerecorded"
                target="_blank"
                rel="noopener noreferrer"
              >
                1.2.1 Audio-only and Video-only (Prerecorded)
              </a>
              .
            </p>
            <p className="govuk-body">
              Captions are not provided for ‘Make your mandatory declarations v2’ and COP reports in
              the help pages so you are unable to read the demo in these videos. This fails WCAG 2.2
              success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#captions-prerecorded"
                target="_blank"
                rel="noopener noreferrer"
              >
                1.2.2 Captions (Prerecorded)
              </a>
              .
            </p>
            <p className="govuk-body">
              Audio descriptions or media alternative pre-recorded (1.2.3) and Audio Description
              (Pre-recorded) (1.2.5) – Transcript and audio are not provided for ‘Make your
              mandatory declarations v2’ and COP reports in the help pages so you are unable to
              follow the demo via audio or transcripts. This fails WCAG 2.2 success criteria{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#audio-description-or-media-alternative-prerecorded"
                target="_blank"
                rel="noopener noreferrer"
              >
                1.2.3 Audio Description or Media Alternative (Prerecorded)
              </a>{' '}
              and{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#audio-description-prerecorded"
                target="_blank"
                rel="noopener noreferrer"
              >
                1.2.5 Audio Description (Prerecorded)
              </a>
              .
            </p>
            <h4 className="govuk-heading-s">PDFs</h4>
            <p className="govuk-body">
              The document title is not set. This means some assistive technologies will not be able
              to inform you of the title. This fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#page-titled"
                target="_blank"
                rel="noopener noreferrer"
              >
                2.4.2 Page Titled
              </a>
              .
            </p>
            <p className="govuk-body">
              The first heading is not nested appropriately. This means assistive technologies will
              not be able to convey the correct meaning of the document. This fails WCAG 2.2 success
              criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#headings-and-labels"
                target="_blank"
                rel="noopener noreferrer"
              >
                2.4.6 Headings and Labels
              </a>
              .
            </p>
            <p className="govuk-body">
              The primary language is not set. This means that assistive technologies do not know
              which language to read it in. This fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#language-of-page"
                target="_blank"
                rel="noopener noreferrer"
              >
                3.1.1 Language of Page
              </a>
              .
            </p>
            <p className="govuk-body">
              The document content is not tagged appropriately. This means assistive technologies
              will not be able to convey the correct meaning of the document. This fails WCAG 2.2
              success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#name-role-value"
                target="_blank"
                rel="noopener noreferrer"
              >
                4.1.2 Name, Role, Value
              </a>
              .
            </p>
            <p className="govuk-body">
              The tab order does not pass accessibility checks. This means that assistive
              technologies may not read documents in the correct order. This fails WCAG 2.2 success
              criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#focus-order"
                target="_blank"
                rel="noopener noreferrer"
              >
                2.4.3 Focus Order
              </a>
              .
            </p>
            <h4 className="govuk-heading-s">Sign in</h4>
            <p className="govuk-body">
              Keycloak page <code>aria-hidden</code> attribute on an element removes the element and
              all its child nodes from the accessibility API. This fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#name-role-value"
                target="_blank"
                rel="noopener noreferrer"
              >
                4.1.2 Name, Role, Value
              </a>
              .
            </p>
            <p className="govuk-body">
              Assistive technologies will not be able to differentiate between elements as they have
              the same IDs. This fails WCAG 2.2 success criterion{' '}
              <a
                href="https://www.w3.org/TR/WCAG22/#parsing"
                target="_blank"
                rel="noopener noreferrer"
              >
                4.1.1 Parsing
              </a>
              .
            </p>
            <h3 className="govuk-heading-m">Disproportionate burden</h3>
            <p className="govuk-body">
              The National Security (CT) referral form on COP currently has a list of accessibility
              issues.
            </p>
            <p className="govuk-body">
              The COP team are aware of this and will not be making changes due to descoping this
              form soon, if you need any help with this form, please contact us (please see feedback
              and contact information section).
            </p>
            <h2 className="govuk-heading-m">What we’re doing to improve accessibility</h2>
            <p className="govuk-body">
              Following testing by the Home Office’s Quality Assurance and Testing Team in August
              2023, we are urgently fixing content which fails to meet the Web Content Accessibility
              Guidelines version 2.1 AA standard.
            </p>
            <p className="govuk-body">
              Our plan describes how we will improve the accessibility of this service. We will:
            </p>
            <ul className="govuk-list govuk-list--bullet">
              <li>train our staff to create accessible services</li>
              <li>
                implement automated and manual accessibility testing in our development process
              </li>
              <li>conduct user research and testing with users who have access needs</li>
              <li>
                put in place alternative arrangements for those who need them and be willing to make
                additional adjustments if these are not enough
              </li>
            </ul>
            <p className="govuk-body">
              We are rebuilding the underlying technology to make COP more accessible.
            </p>
            <p className="govuk-body">We will fix or remove the non-compliant content.</p>
            <h2 className="govuk-heading-m">Preparation of this accessibility statement</h2>
            <p className="govuk-body">
              This statement was first published on 17 September 2020. It was last reviewed on 17
              October 2023.
            </p>
            <p className="govuk-body">
              COP was last tested in August 2023. The test was carried out by the Home Office’s
              Quality Assurance and Testing team.
            </p>
            <p className="govuk-body">
              We tested the service based on a user’s ability to complete key journeys. All parts of
              the chosen journeys were tested.
            </p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AccessibilityStatement;
