// Global imports
import { Link, Utils } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import config from 'react-global-configuration';
import { useTranslation } from 'react-i18next';
import { useNavigation } from 'react-navi';

// Styles
import './Footer.scss';

const DEFAULT_CLASS = 'hods-footer';
const Footer = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const serviceDeskUrl = config.get('serviceDeskUrl');
  const getOnClick = (item) => {
    return async (e) => {
      e.preventDefault();
      await navigation.navigate(item.href);
    };
  };

  const LINKS = [
    { id: 'help', href: serviceDeskUrl, target: '_blank', rel: 'noopener noreferrer' },
    { id: 'privacy', href: '/privacy-and-cookie-policy', hasOnClick: true },
    { id: 'accessibility', href: '/accessibility-statement', hasOnClick: true },
  ];

  const classes = Utils.classBuilder(DEFAULT_CLASS, []);
  return (
    <footer className={classes()} role="contentinfo">
      <div className={`${classes('inner')} hods-width-container`}>
        <div className={classes('container')}>
          <div className={classes('navigation')}>
            <ul id="navigation" className={classes('navigation-list')}>
              {LINKS.map((link) => {
                const attrs = { ...link };
                if (link.hasOnClick) {
                  attrs.onClick = getOnClick(link);
                  delete attrs.hasOnClick;
                }
                return (
                  <li key={link.id} className={classes('navigation-item')}>
                    <Link {...attrs} className={classes('link')}>
                      {t(`footer.${link.id}`)}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={classes('content')}>
            A Home Office Digital, Data and Technology service
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
