import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const FormPanelContext = createContext({
  formPanelContext: {},
});

export const FormPanelContextProvider = ({ children }) => {
  const [formPanelContext, setFormPanelContext] = useState(null);

  return (
    <FormPanelContext.Provider value={{ formPanelContext, setFormPanelContext }}>
      {children}
    </FormPanelContext.Provider>
  );
};

FormPanelContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
