// Global import(s)
import Keycloak from 'keycloak-js';
import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

const KeycloakContext = createContext({
  initialized: false,
  keycloak: null,
});

const KeycloakProvider = ({ children, config }) => {
  const [initialized, setInitialized] = useState(false);
  const [keycloak, setKeycloak] = useState(null);

  useEffect(() => {
    const keycloakInstance = new Keycloak(config);
    keycloakInstance.onAuthLogout = () => {
      // eslint-disable-next-line
      console.log('onAuthLogout called');
    };
    keycloakInstance
      ?.init({
        onLoad: 'login-required',
        checkLoginIframe: false,
      })
      .then((authenticated) => {
        if (authenticated) {
          setKeycloak(keycloakInstance);
          setInitialized(true);
        } else {
          keycloakInstance.login();
        }
      });
  }, [config]);

  return (
    <KeycloakContext.Provider value={{ initialized, keycloak }}>
      {children}
    </KeycloakContext.Provider>
  );
};

KeycloakProvider.propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes.shape({
    realm: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
  }).isRequired,
};

const useKeycloak = () => useContext(KeycloakContext);

export { KeycloakContext, KeycloakProvider, useKeycloak };
