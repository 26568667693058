// Global imports
import React from 'react';

const ConfirmationCOIR = () => {
  return (
    <>
      <p className="govuk-body">We have sent you a confirmation email.</p>
      <p className="govuk-body">
        <strong>What happens next</strong>
      </p>
      <p className="govuk-body">
        The Country Policy and Information Team will check your request and may contact you if they
        need more information.
      </p>
      <p className="govuk-body">
        We usually aim to respond within 5 working days but this will depend on your request.
      </p>
      <p className="govuk-body">
        <a href="/">Go to homepage</a>
      </p>
    </>
  );
};

export default ConfirmationCOIR;
