import React, { useEffect, useState, createContext } from 'react';
import PropTypes from 'prop-types';
import { useKeycloak } from './KeycloakContext';

const RBAC = {
  COP_ADMIN: 'cop_admin',
  TARGET_CREATE: 'target_create',
  TARGET_READ: 'target_read',
  TARGET_UPDATE: 'target_update',
};

const PermissionContext = createContext({});

const PermissionProvider = ({ children }) => {
  const [canRead, setCanRead] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);

  const { keycloak } = useKeycloak();

  const loggedInUserRoles = keycloak?.tokenParsed?.realm_access?.roles;

  const hasReadPermission = (roles) => {
    if (roles?.length) {
      return [RBAC.TARGET_READ, RBAC.COP_ADMIN].some((role) => {
        return roles.includes(role);
      });
    }
    return false;
  };

  const hasUpdatePermission = (roles) => {
    if (roles?.length) {
      return [RBAC.TARGET_UPDATE, RBAC.COP_ADMIN].some((role) => {
        return roles.includes(role);
      });
    }
    return false;
  };

  useEffect(() => {
    if (loggedInUserRoles?.length) {
      setCanRead(hasReadPermission(loggedInUserRoles));
      setCanUpdate(hasUpdatePermission(loggedInUserRoles));
    }
  }, [loggedInUserRoles]);

  return (
    <PermissionContext.Provider value={{ canRead, canUpdate }}>
      {children}
    </PermissionContext.Provider>
  );
};

PermissionProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export { PermissionContext, PermissionProvider };
