import React, { createContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const GROUP_TYPE_ROLE = 2;

export const GroupsContext = createContext({
  groups: {},
  setGroups: () => {},
  nonRoleGroups: [],
  setNonRoleGroups: () => {},
});

export const GroupsContextProvider = ({ children }) => {
  const [groups, setGroups] = useState([]);
  const [nonRoleGroups, setNonRoleGroups] = useState([]);

  const setupNonRoleGroups = () => {
    const filteredGroups = groups.filter((group) => group.grouptypeid !== GROUP_TYPE_ROLE) || [];
    setNonRoleGroups(filteredGroups);
  };

  useEffect(() => {
    if (groups?.length) {
      setupNonRoleGroups();
    }
  }, [groups]);

  const value = useMemo(
    () => ({
      groups,
      setGroups,
      nonRoleGroups,
      setNonRoleGroups,
    }),
    [groups, nonRoleGroups]
  );

  return <GroupsContext.Provider value={value}>{children}</GroupsContext.Provider>;
};

GroupsContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
